var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        footer: _vm.footer,
        "content-size": "large",
        "show-overlay": _vm.open,
        title: _vm.title,
        back: _vm.showBackBtn && !_vm.switchAddress ? _vm.accessBack : null,
        close: _vm.close,
      },
    },
    [
      _vm.walletType === _vm.types.OVERVIEW
        ? _c(
            "div",
            {
              staticClass: "mx-auto pt-5",
              staticStyle: { "max-width": "650px", width: "100%" },
            },
            _vm._l(_vm.buttons, function (btn, key) {
              return _c(
                "div",
                { key: key, staticClass: "mb-5" },
                [
                  _c(
                    "mew-button",
                    {
                      class: btn.class,
                      staticStyle: { height: "160px" },
                      attrs: {
                        "has-full-width": "",
                        "color-theme": "greyMedium",
                        "btn-style": "outline",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return btn.fn.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-left d-flex align-center justify-space-between px-2",
                          staticStyle: { width: "100%" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mew-heading-2 textDark--text" },
                            [_vm._v(" " + _vm._s(btn.label) + " ")]
                          ),
                          _c("img", {
                            staticClass: "mr-4 d-none d-sm-block",
                            attrs: { width: "80", src: btn.icon },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.walletType === _vm.types.KEYSTORE
        ? _c("access-wallet-keystore", {
            staticClass: "mb-6",
            attrs: { "handler-access-wallet": _vm.accessHandler },
            on: { unlock: _vm.unlockWallet },
          })
        : _vm._e(),
      _vm.walletType === _vm.types.MNEMONIC
        ? _c("access-wallet-mnemonic", {
            staticClass: "mb-6",
            attrs: {
              "handler-access-wallet": _vm.accessHandler,
              "switch-address": _vm.switchAddress,
            },
            on: { unlock: _vm.unlockWallet },
          })
        : _vm.walletType === _vm.types.PRIVATE_KEY
        ? _c("access-wallet-private-key", {
            staticClass: "mb-6",
            attrs: { "handler-access-wallet": _vm.accessHandler },
            on: { unlock: _vm.unlockWallet },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }